import { HUB } from "../../hub";
// import { Gems } from "../../gems";
// import router from "../../router";

const state = {
    hierarchy: [],
    bckHierarchy: [],
    elemHasChildren: false,
    elemsInserteds: false,
};

const getters = {
    Hierarchy: (state) => state.hierarchy,
};

const actions = {

    async GetHierarchy({ state, dispatch, commit }) {
        const actualHierarchy = state.hierarchy

        const response = await HUB.CallBackEnd({
            action: HUB.Action('get-hierarchy')
        })

        commit('setHierarchy', response.data)
        dispatch('SeeHierarchy', { arr: response.data, actualArr: actualHierarchy })
    },

    async InsertHierarchy({ state, commit, dispatch }, { user, subordinates, newHeadship, actualHeadship }) {
        let arrUsersIds

        if (subordinates) {
            await commit('insertInHierarchy', { user, subordinates, newHeadship, actualHeadship })

            if (!state.elemsInserteds)
                return

            arrUsersIds = subordinates.map((user) => user.user_id)
        } else {
            arrUsersIds = user.map((user) => user.user_id)
        }

        await HUB.CallBackEnd({
            action: HUB.Action('insert-hierarchy'),
            data: {
                arr_users: arrUsersIds,
                new_headship: newHeadship,
                actual_headship: actualHeadship
            }
        })

        dispatch('GetHierarchy')
    },

    async RemoveHierarchy({ dispatch }, user) {
        await HUB.CallBackEnd({
            action: HUB.Action('remove-hierarchy'),
            data: {
                user_id: user.user_id,
                headship: user.headship_user_id
            }
        })

        dispatch('GetHierarchy')
    },

    SeeHierarchy({ dispatch }, { arr, actualArr }) {
        if (actualArr.length == 0)
            return

        Object.values(arr).forEach(elem => {
            let sameUser = Object.values(actualArr).findIndex(actualElem => actualElem.user_id == elem.user_id)

            if (sameUser != -1 && Object.values(actualArr)[sameUser].showChildren) {
                elem.showChildren = true
                dispatch('SeeHierarchy', { arr: elem.children, actualArr: Object.values(actualArr)[sameUser].children })
            }
        })

        return
    },

    CreateDragPreview(_, { e, container }) {
        e.dataTransfer.effectAllowed = 'move'
        container.style.backgroundColor = 'white'
        container.style.width = '50%'
        container.style.position = 'absolute'
        container.style.left = '-900px'
        container.style.bottom = '-900px'
        container.style.zIndex = '-1'
        container.style.padding = '5px'
        container.style.color = 'black'
        container.id = "drag-preview"

        document.body.appendChild(container)
        container.style.height = container.clientHeight + 50 + `px`
        e.dataTransfer.setDragImage(container, 0, 0)
    },

    DeleteDragPreview() {
        document.getElementById('drag-preview').remove()
    }

};

const mutations = {
    setHierarchy: (state, hierarchy) => state.hierarchy = hierarchy,

    insertInHierarchy: (state, { user, subordinates, newHeadship, actualHeadship }) => {
        let children = []
        let subordinatesFiltered = []

        subordinates.forEach(subordinate => {
            let ind = Object.values(user.children).findIndex(sub => sub.user_id == subordinate.user_id)
            if (ind == -1)
                subordinatesFiltered.push(subordinate)
        })

        if (subordinatesFiltered.length == 0)
            state.elemsInserteds = false

        for (let i = 0; i < Object.values(user.children).length; i++)
            children.push(Object.values(user.children)[i])

        subordinatesFiltered.forEach(subordinate => {
            subordinate.headship_user_id = newHeadship

            if (!actualHeadship) {
                subordinate.showChildren = false
                subordinate.children = []

                if (!state.elemHasChildren && subordinate.has_children)
                    state.elemHasChildren = true
            }
        })

        children.push(...subordinatesFiltered)

        user.children = children.sort((a, b) => {
            if (a.name > b.name)
                return 1

            if (a.name == b.name)
                return 0

            if (a.name < b.name)
                return -1
        })

        state.elemsInserteds = true
    },

    insertCEO: (state, users) => {
        let copyHierarchy = []
        // let children = []


        Object.values(state.hierarchy).forEach(user => {
            // Object.values(user.children).forEach(child => {
            //     if (children.findIndex(elem => elem.user_id = child.user_id) == -1)
            //         children.push(child)
            // })
            copyHierarchy.push(user)
        })

        users.forEach(user => {
            user.headship_user_id = -1
            user.showChildren = false
            user.children = []
            copyHierarchy.push(user)
        })

        state.hierarchy = copyHierarchy.sort((a, b) => {
            if (a.name > b.name)
                return 1

            if (a.name == b.name)
                return 0

            if (a.name < b.name)
                return -1
        })
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
