<script setup>
import { defineProps, defineEmits, ref, onMounted, onUpdated } from 'vue';
import { HUB } from '../../hub';
import { Gems } from '../../gems';

const emits = defineEmits(['InsertTeamUser', 'DeleteTeamUser'])
const props = defineProps({
    team: Object
})

const divTeamHeader = ref(null)
const showUsers = ref(false)

function GetIndexOf(array, field, value) {
    return array.findIndex(param => param[field] == value)
}

function HandleDrop(ev) {
    const user = JSON.parse(ev.dataTransfer.getData('application/json'))

    if (GetIndexOf(props.team.users, 'id', user.id) !== -1) {
        Gems.Telegram('Utilizador já está na equipa', 'tele-not-allowed')
        return
    }

    emits('InsertTeamUser', (team, users) => {
        team.users.push({
            id: user.id,
            name: user.name,
            internal: user.internal,
            full_name: user.full_name
        })

        team.users.sort((a, b) => a.full_name.localeCompare(b.full_name))

        showUsers.value = true

        const originalUserIndex = GetIndexOf(users, 'id', user.id)
        const originalUserTeamIndex = GetIndexOf(users[originalUserIndex].teams, 'team_id', props.team.team_id)

        if (originalUserTeamIndex !== -1)
            return

        users[originalUserIndex].teams.splice(originalUserTeamIndex, 0, props.team)

        HUB.CallBackEnd({
            action: HUB.Action('insert-team-user'),
            data: {
                user_id: user.id,
                team_id: props.team.team_id
            }
        })


    })
}

function DeleteTeamUser(user) {
    const userOnTeamIndex = GetIndexOf(props.team.users, 'id', user.id)

    if (userOnTeamIndex === -1)
        return

    emits('DeleteTeamUser', async (team, users) => {
        team.users.splice(userOnTeamIndex, 1)

        const originalUserIndex = GetIndexOf(users, 'id', user.id)
        const originalUserTeamIndex = GetIndexOf(users[originalUserIndex].teams, 'team_id', props.team.team_id)

        if (originalUserTeamIndex !== -1) {
            users[originalUserIndex].teams.splice(originalUserTeamIndex, 1)

            const response = HUB.CallBackEnd({
                action: HUB.Action('delete-team-user'),
                data: {
                    user_id: user.id,
                    team_id: props.team.team_id
                }
            })

            if (!response.error) {
                Gems.Telegram('Colaborador excluído', 'tele-success')
            }

        }
    })
}


onMounted(() => {
    Gems.Tooltip(divTeamHeader.value, props.team.team_internal, 'tooltip')
})

onUpdated(() => {
    Gems.Tooltip(divTeamHeader.value, props.team.team_internal, 'tooltip')
})

</script>

<template>
    <div class="div-team-card inputs-border" @drop.prevent="HandleDrop($event)" @dragover.prevent>
        <div class="div-inner-team-card">
            <div ref="divTeamHeader" class="div-team-header default-cursor">

                <div class="div-team-name">
                    {{ props.team.team_name }}
                </div>

                <i class="fa-solid clickable icon"
                    :class="{ 'fa-chevron-down': !showUsers, 'fa-chevron-up': showUsers }"
                    @click="showUsers = !showUsers"></i>
            </div>
            <div class="div-team-users" v-if="props.team.users.length > 0" v-show="showUsers">
                <div class="div-team-user default-cursor" v-for="(user, index) in props.team.users" :key="index">
                    <div @mouseenter="Gems.Tooltip($event.target, `${user.internal} | ${user.full_name}`, 'tooltip')"
                        class="div-team-user-name">
                        {{ user.name }}
                    </div>

                    <i @mouseenter="Gems.Tooltip($event.target, `Remover ${user.name} da equipa`, 'tooltip')"
                        class="icon fas fa-user-times clickable" @click="DeleteTeamUser(user)"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.div-team-card {
    margin: 10px 0;
    border-radius: 5px;
}

.div-inner-team-card {
    border-radius: 5px;
    background-color: black;
}

.div-team-header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2em;
}

.div-team-name {
    width: 100%;
    text-align: center;
}

.div-team-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}

.div-team-users {
    padding: 20px;
}

.icon:hover {
    color: var(--default-hover-color);
}
</style>