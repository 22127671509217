<script setup>
import { defineEmits, defineProps, onMounted, ref } from 'vue'
import { Gems } from '@/gems'

const emits = defineEmits(['import', 'upload-file'])
const props = defineProps(['tableName'])
const label = ref(null)
const defaultPlaceholder = 'Selecionar ficheiro [ CSV UTF-8 (Comma delimited) (*.csv) ]'

function OpenFileSelect() {
  document.getElementById(props.tableName).click()
}

function SetPlaceholder() {
  const input = document.getElementById(props.tableName)

  label.value.placeholder = input.files[0].name

  emits('upload-file', { input: input, type: props.tableName, inputLabel: label.value });
}

function Import() {
  document.getElementById(`btn-import-${props.tableName}`).classList.remove('blink-import')
  emits('import', props.tableName)
  label.value.placeholder = defaultPlaceholder
}

onMounted(() => {
  if (label.value)
    Gems.Tooltip(label.value, defaultPlaceholder, "tooltip")
})

</script>

<template>
  <input type="text" ref="label" class="inputfile clickable" @click="OpenFileSelect()" :placeholder="defaultPlaceholder"
    readonly />
  <input type="file" class="hidden-input" :id="props.tableName" @change="SetPlaceholder()" />


  <div class="inputs-border div-btn-import hover-inputs-border">
    <button :id="`btn-import-${props.tableName}`" class="btn-import clickable" @click="Import">IMPORTAR <span
        class="arrow">&#8593;</span></button>
  </div>
</template>

<style scoped>
.inputfile {
  width: -webkit-fill-available;
  color: initial;
}

.inputfile,
.btn-import {
  text-align: center;
  padding: 12px;
  border-radius: 7px;
  font-weight: 300;
  font-family: 'Space Grotesk', sans-serif;
  border: none;
}

.btn-import {
  background-color: black;
}

/* .inputfile:hover {
  color: var(--default-hover-color) !important;
}

.inputfile::placeholder {
  color: var(--default-app-color);
}  */

.div-inputs {
  flex-grow: 1;
}

.div-btn-import {
  margin-left: 5px;
  margin-bottom: 0px;
}

.btn-import {
  width: 232px;
}

.btn-import:hover {
  color: var(--default-hover-color) !important;
}
</style>
