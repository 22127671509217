<script setup>

import HierarchyUsersList from './HierarchyUsersList'
import { defineProps, defineEmits } from 'vue'
import { useStore } from "vuex"
import { Gems } from '../gems'

const store = useStore()
const emits = defineEmits(['drop-hierarchy'])
const defaultImage = require('../images/default.png')

const props = defineProps({
    hierarchy: {
        type: [Array, Object],
        default: () => []
    },
})

function DragFromTree(e, user) {
    e.dataTransfer.setData('arrUsers', JSON.stringify([user]))
    e.dataTransfer.setData('actual-headship', JSON.stringify(user.headship_user_id))

    const container = e.target.parentElement.cloneNode(true)
    Array.from(container.getElementsByClassName('icon')).forEach(i => {
        i.parentElement.parentElement.style.gridTemplateColumns = 'fit-content(100%) fit-content(100%) fit-content(100%)'
        i.parentElement.style.display = 'none'
    })
    store.dispatch('CreateDragPreview', { e, container })
}


async function RemoveHierarchy(user) {
    Gems.Telegram(`Remover [${user.name}] desta ramificação da hierarquia?`, 'tele-info', '', 'tele-cancel')
        .then(
            () => store.dispatch('RemoveHierarchy', user),
            () => { },
        )
}

function DefineImage(user) {
    const elem = document.getElementById(`div-user-photo-${user.user_id}`)
    elem.style.backgroundImage = `url(${defaultImage})`
    elem.src = `${defaultImage}`
}
</script>

<template>
    <div v-for="user in props.hierarchy" :key="user.user_id">
        <div v-if="!user.moved">
            <div class="user-data draggable" draggable="true" @dragover.prevent
                @dragend="store.dispatch('DeleteDragPreview')" :class="{ 'children-opened': user.showChildren }"
                @drop="emits('drop-hierarchy', { user, e: $event })" @dragstart="DragFromTree($event, user)">

                <span>
                    <i class="icon fas icon-children-control"
                        :class="{ 'fa-plus-square': !user.showChildren, 'fa-minus-square': user.showChildren }"
                        v-if="Object.keys(user.children).length > 0" @click="user.showChildren = !user.showChildren" />
                </span>

                <span class="div-photo">
                    <img :id="`div-user-photo-${user.user_id}`" class="div-user-photo"
                        :style="`background-image: url(${user.src ?? defaultImage})`" :src="user.src ?? defaultImage"
                        @error="DefineImage(user)">
                </span>

                <span class="line-clamp line-clamp-1">{{ user.internal }} | {{ user.name }}</span>

                <span>
                    <i class="icon fas fa-times remove-hierarchy" @click="RemoveHierarchy(user)" />
                </span>

            </div>

            <div class="children" v-if="Object.keys(user.children).length > 0" v-show="user.showChildren">
                <HierarchyUsersList :hierarchy="user.children" @drop-hierarchy="emits('drop-hierarchy', $event)" />
            </div>
        </div>
    </div>
</template>

<style scoped>
.user-data {
    width: 55%;
    display: grid;
    grid-template-columns: 15px fit-content(100%) fit-content(100%) fit-content(100%);
    gap: 15px;
    padding: 3px 10px;
    border-radius: 7px;
    margin: 5px;
}

.user-data:hover {
    color: var(--default-hover-color);
}

.user-data span {
    align-self: center;
}

.children {
    padding-left: 40px;
}

.div-user-photo {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-size: cover;
    background-position: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, .5);
    -o-object-fit: cover;
    object-fit: cover;
    vertical-align: middle;
}

.icon {
    height: fit-content;
    align-self: center;
    cursor: pointer;
}

.remove-hierarchy {
    margin: 2px 0 0 -5px;
    font-size: 1em;
}

.remove-hierarchy:hover {
    color: orangered;
}

.inactive {
    opacity: .3;
}

.children-opened {
    background-color: #231f20;
}

.draggable {
    cursor: move;
}</style>
