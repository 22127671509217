/*
*** Para usar em javascript/vue.js : Comentar/Descomentar a ultima linha do código ***

//////////////////////
// EXEMPLOS TOOLTIP //
//////////////////////

Gems.Tooltip(elemento-html, 'texto-da-tooltip', 'classe-css');

///////////////////////
// EXEMPLOS LANGUAGE //
///////////////////////
 
Gems.Language(['brown','fox'], function(){
    document.getElementById('divLiteral').innerHTML = `the quick ${arguments[0]} ${arguments[1]}`;
});

///////////////////////
// EXEMPLOS TELEGRAM //
///////////////////////

// Mensagem/alert (Sem botões)
Gems.Telegram('Texto da mensagem', 'class-css-do-corpo-do-alert')

// Mensagem/alert modal (1 botão: "OK")
Gems.Telegram('Texto da mensagem', 'class-css-do-corpo-do-alert', 'class-css-do-botao-OK') // class-css-do-botao-OK ou simplesmente vazio
Gems.Telegram('Texto da mensagem', 'class-css-do-corpo-do-alert', '')

// Mensagem/alert modal com execução de código no botão "OK" (1 botão)
Gems.Telegram('Texto da mensagem', 'class-css-do-corpo-do-alert', '').then(
    () => { FuncaoDoBotaoOK(parametros) }
)

// Mensagem/confirm modal com execução de código no botão "OK" (2 botões: "OK" e "CANCEL") // class-css-do-botao-CANCEL ou simplesmente vazio
Gems.Telegram('Texto da mensagem', 'class-css-do-corpo-do-alert', 'class-css-do-botao-OK', 'class-css-do-botao-CANCEL').then(
    () => { FuncaoDoBotaonOK(parametros) },
    () => {}
)

// Mensagem/confirm modal com execução de código em ambos os botões (2 botões: "OK" e "CANCEL")
Gems.Telegram('Texto da mensagem', 'class-css-do-corpo-do-alert', '', '').then(
    () => { FuncaoBotaoOK(parametros) },
    () => { FuncaoBotaoCANCEL(parametros) }
)

Dica:
Passar como parametro, o nome da classe css dos botões, é opcional; 
Ou seja, definir o parametro da classe css como vazio ('') já faz com que o botão apareça

*/

class Gems {

  static Telegram = (message, css, cssOk, cssCancel) => {
    let divModal;
    let btnOk;
    let btnCancel;
    let top = 15;
    const modalOpacity = 0.1;
    const divModalColor = "black";

    const div = document.createElement("div");
    div.style.position = "fixed";
    div.style.right = "1.5%";
    div.style.maxWidth = "250px";
    div.style.minWidth = "200px";
    div.style.boxShadow = "4px 4px 10px 0px rgba(0, 0, 0, 0.5)";
    div.style.zIndex = '99999';
    div.className = css;

    const divText = document.createElement("div");
    divText.style.padding = "25px";
    divText.style.wordBreak = "break-word";
    divText.innerHTML = message;
    div.appendChild(divText);
    document.body.appendChild(div);

    if (cssOk !== undefined) {
      const divButtons = document.createElement("div");
      divButtons.style.textAlign = "center";
      divButtons.style.borderRadius = "inherit";
      div.appendChild(divButtons);

      div.style.opacity = 0;
      div.style.maxWidth = "400px";
      div.style.minWidth = "300px";
      div.style.padding = "12px 12px 10px 12px";
      div.style.boxShadow = "0 0 15px 0 rgba(0, 0, 0, 0.5)";
      div.style.left = "50%";
      div.style.marginLeft = (div.offsetWidth / 2) * -1 + "px";
      divText.style.padding = "11px 10px 15px 10px";
      divText.style.textAlign = "left";

      divModal = document.createElement("div");
      divModal.style.backgroundColor = divModalColor;
      divModal.style.position = "fixed";
      divModal.style.width = "100%";
      divModal.style.zIndex = 98;
      divModal.style.left = 0;
      divModal.style.top = 0;
      divModal.style.bottom = 0;
      divModal.style.right = 0;
      divModal.style.opacity = modalOpacity;
      divModal.style.height = document.body.clientHeight + "px";
      if (window.innerHeight > document.body.clientHeight) {
        divModal.style.height = window.innerHeight + "px";
      }
      document.body.appendChild(divModal);

      if (cssCancel !== undefined) {
        btnCancel = document.createElement("input");
        btnCancel.type = "button";
        btnCancel.value = "Cancel";
        btnCancel.style.margin = "0 1px 0 10px";
        btnCancel.style.cursor = "pointer";
        btnCancel.style.height = "28px";
        btnCancel.style.width = "85px";
        btnCancel.style.lineHeight = 0;
        btnCancel.style.fontSize = "0.8em";
        btnCancel.style.setProperty("float", "right", "important");

        btnCancel.className = cssCancel;
        if (typeof cssCancel != "string" || cssCancel.length == 0) {
          btnCancel.style.border = "solid 1px rgb(150, 150, 150)";
          btnCancel.style.background = "var(--light-gray-color)";
          btnCancel.style.color = "inherit";
          btnCancel.style.borderRadius = "inherit";
          btnCancel.style.fontFamily = "inherit";
          btnCancel.style.fontWeight = "normal";
        }

        divButtons.appendChild(btnCancel);
      }

      btnOk = document.createElement("input");
      btnOk.type = "button";
      btnOk.value = "OK";
      btnOk.style.cursor = "pointer";
      btnOk.style.height = "28px";
      btnOk.style.width = "85px";
      btnOk.style.lineHeight = 0;
      btnOk.style.fontSize = "0.8em";
      btnOk.style.setProperty("float", "right", "important");

      btnOk.className = cssOk;
      if (typeof cssOk != "string" || cssOk.length == 0) {
        btnOk.style.border = "solid 1px rgb(0, 70, 185)";
        btnOk.style.background = "rgb(40, 110, 225)";
        btnOk.style.color = "white";
        btnOk.style.borderRadius = "inherit";
        btnOk.style.fontFamily = "inherit";
        btnOk.style.fontWeight = "normal";
      }

      divButtons.appendChild(btnOk);
    }

    if (cssOk === undefined) {
      div.style.top = div.offsetHeight * -1 + "px"; // top begin
      let topStop = document.body.clientTop + top; // top stop

      div.style.transition = "top 400ms ease-out";
      div.style.top = topStop + "px";
      setTimeout(() => {
        div.style.transition = "none";
      }, 400);

      setTimeout(() => {
        div.addEventListener("webkitTransitionEnd", function () {
          div.remove();
        });
        div.addEventListener("transitionend", function () {
          div.remove();
        });

        div.style.transition = "opacity 1500ms linear";
        div.style.opacity = 0;
      }, 5000);
    } else {
      div.style.msTransform = "scale(0, 0)";
      div.style.transform = "scale(0, 0)";
      div.style.opacity = 1;
      div.style.top = window.innerHeight / 2.1 - div.offsetHeight + "px";
      //alert(screen.height + ' ' + window.innerHeight + ' ' + div.offsetHeight);

      const recede = 0.95;

      div.addEventListener("webkitTransitionEnd", function () {
        setTimeout(() => {
          div.addEventListener("webkitTransitionEnd", function () {
            div.style.transition = "all 90ms ease-in-out";
            div.style.msTransform = "scale(1, 1)";
            div.style.transform = "scale(1, 1)";
            //setTimeout(() => { div.style.transition = 'none'; }, 150);
          });
        }, 1);

        div.style.transition = "all 90ms ease-in-out";
        div.style.msTransform = "scale(" + recede + ", " + recede + ")";
        div.style.transform = "scale(" + recede + ", " + recede + ")";
      });

      div.addEventListener("transitionend", function () {
        setTimeout(() => {
          div.addEventListener("transitionend", function () {
            div.style.transition = "all 90ms ease-in-out";
            div.style.msTransform = "scale(1, 1)";
            div.style.transform = "scale(1, 1)";
            //setTimeout(() => { div.style.transition = 'none'; }, 150);
          });
        }, 1);

        div.style.transition = "all 90ms ease-in-out";
        div.style.msTransform = "scale(" + recede + ", " + recede + ")";
        div.style.transform = "scale(" + recede + ", " + recede + ")";
      });


      div.style.transition = "all 150ms ease-out";
      div.style.msTransform = "scale(1.06, 1.06)";
      div.style.transform = "scale(1.06, 1.06)";

      /*
            div.addEventListener("transitionend", function () {
              setTimeout(() => {
                div.style.transition = "all 500ms ease-out";
                div.style.msTransform = "scale(1, 1)";
                div.style.transform = "scale(1, 1)";
              }, 1);
            });
      
            div.style.transition = "all 200ms ease-in";
            div.style.msTransform = "scale(0.95, 0.95)";
            div.style.transform = "scale(0.95, 0.95)";
      */
    }

    if (cssOk !== undefined) {
      btnOk.focus();

      return new Promise((resolve, reject) => {
        btnOk.onclick = () => {
          divModal.remove();
          div.remove();
          resolve();
        };

        if (cssCancel !== undefined) {
          btnCancel.onclick = () => {
            divModal.remove();
            div.remove();
            reject();
          };
        }
      });
    }
  };

  static Tooltip = (element, tooltipText, css) => {
    element.addEventListener('mousemove', (event) => {
      if (document.getElementsByClassName(css)[0] === undefined) {
        var divTooltip = document.createElement('div');
        divTooltip.className = css;
        document.body.appendChild(divTooltip);
      }

      document.getElementsByClassName(css)[0].style.position = 'absolute';
      document.getElementsByClassName(css)[0].style.zIndex = '99999';
      document.getElementsByClassName(css)[0].style.display = '';
      document.getElementsByClassName(css)[0].innerText = tooltipText;

      let tooltipWidth = document.getElementsByClassName(css)[0].offsetWidth;
      let tooltipHeight = document.getElementsByClassName(css)[0].offsetHeight;
      let screenWidth = document.body.clientWidth;
      let screenHeight = document.documentElement.scrollHeight;
      let tooltipX = event.pageX + 20;
      let tooltipY = event.pageY + 10;

      if (tooltipX + tooltipWidth > screenWidth) {
        tooltipX = event.pageX - tooltipWidth - 10;
      }

      if (tooltipY + tooltipHeight > screenHeight) {
        tooltipY = event.pageY - tooltipHeight;
      }

      document.getElementsByClassName(css)[0].style.top = tooltipY + 'px';
      document.getElementsByClassName(css)[0].style.left = tooltipX + 'px';
    });

    element.addEventListener('mouseout', () => {
      document.getElementsByClassName(css)[0].style.display = 'none';
    });

    element.addEventListener('mouseup', () => {
      document.getElementsByClassName(css)[0].style.display = 'none';
    });

    if (document.getElementsByClassName(css)[0] !== undefined) {
      document.addEventListener('mouseover', () => {
        document.getElementsByClassName(css)[0].style.display = 'none';
      });
    }
  };

  static ExportExcel = (strData) => {
    const split = ".xls";
    let filename = strData.substr(0, strData.indexOf(split));
    strData = strData.split(split)[1];
    let meta = "<meta charset=UTF-8 />";
    let uri = "data:application/vnd.ms-excel," + encodeURIComponent(meta + strData);

    let link = document.createElement("a");
    link.href = uri;
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  static TooltipIfNeeded = (elem) => {
    if (elem.scrollHeight > elem.clientHeight) {
      Gems.Tooltip(elem, elem.innerText, "tooltip");
    }
  };

  static CallWS = async ({ app = "", action = "", body = {} }) => {
    let envPrefix = Gems.EnvPrefix()
    let url = `https://${envPrefix}corews.inpeoplehub.com?p1=${app}&p2=${action}`

    const fetchOptions = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: body instanceof FormData ? body : new URLSearchParams(body).toString()
    }

    if (body instanceof FormData)
      delete fetchOptions.headers // let browser set the appropriate headers when send files

    return (await fetch(url, fetchOptions)).json().catch(() => { return [] })
  };

  static FormatDecimals(value, len = null) {
    let formatedNumber = parseFloat(value ?? 0).toLocaleString('pt-PT')

    if (len != null)
      return Gems.TruncateDecimals(formatedNumber, len, true)

    return formatedNumber
  }

  static TruncateDecimals(value, len, formated = false) {
    if (value == null)
      return null

    let splitByChar = formated ? ',' : '.'
    let newValue = value.toString().split(splitByChar)

    if (newValue[1])
      return newValue[0] + splitByChar + newValue[1].substring(0, len)

    if (newValue[0] == 100)
      len = 1

    if (len == 0)
      splitByChar = ''

    return newValue[0] + splitByChar + '0'.repeat(len)
  }

  static EnvPrefix() {
    if (window.parent.location.hostname.match("dev|localhost"))
      return 'dev'

    return ''
  }

  static HubUrl() {
    let subdomain = Gems.EnvPrefix();
    if (subdomain != '')
      subdomain += '.';

    return `https://${subdomain}inpeoplehub.com`;
  }

  static GeneratePassRules(elementContainer) {
    const ul = document.createElement('ul')
    const hasAtLeast12Chars = document.createElement('li')
    const hasUpperAndLowerChars = document.createElement('li')
    const hasNumbers = document.createElement('li')
    const hasSymbols = document.createElement('li')
    const hasUsername = document.createElement('li')
    const isPassAndConfirmEquals = document.createElement('li')

    hasAtLeast12Chars.innerText = 'Mínimo de 12 caracteres'
    hasUpperAndLowerChars.innerText = 'Incluir maiúsculas e minúsculas'
    hasNumbers.innerText = 'Incluir números'
    hasSymbols.innerText = 'Incluir símbolos'
    hasUsername.innerText = 'Não deve conter o username'
    isPassAndConfirmEquals.innerText = 'As passwords devem ser iguais'

    hasAtLeast12Chars.className = 'pass-rule-warning'
    hasUpperAndLowerChars.className = 'pass-rule-warning'
    hasNumbers.className = 'pass-rule-warning'
    hasSymbols.className = 'pass-rule-warning'
    hasUsername.className = 'pass-rule-warning'
    isPassAndConfirmEquals.className = 'pass-rule-warning'

    hasAtLeast12Chars.id = 'has-at-least-12-chars'
    hasUpperAndLowerChars.id = 'has-upper-and-lower-chars'
    hasNumbers.id = 'has-numbers'
    hasSymbols.id = 'has-symbols'
    hasUsername.id = 'has-username'
    isPassAndConfirmEquals.id = 'is-pass-equals'

    ul.appendChild(hasAtLeast12Chars)
    ul.appendChild(hasUpperAndLowerChars)
    ul.appendChild(hasNumbers)
    ul.appendChild(hasSymbols)
    ul.appendChild(hasUsername)
    ul.appendChild(isPassAndConfirmEquals)

    elementContainer.appendChild(ul)
  }

  static ControlPassRuleStyle(id, isRuleOk) {
    const elem = document.getElementById(id)

    if (elem) {
      if (isRuleOk) {
        elem.classList.add('pass-rule-ok')
        elem.classList.remove('pass-rule-warning')
      } else {
        elem.classList.add('pass-rule-warning')
        elem.classList.remove('pass-rule-ok')
      }
    }
  }

  static GetPassRulesConfirmation(password, confirmPassword, username) {
    let hasAtLeast12Chars = false
    let hasUpperAndLowerChars = false
    let hasNumbers = false
    let hasSymbols = false
    let hasUsername = false
    let isPassAndConfirmEquals = false

    if (password == '')
      return {}

    if (password.length >= 12)
      hasAtLeast12Chars = true

    if (password.match(/[a-z]/) && password.match(/[A-Z]/))
      hasUpperAndLowerChars = true

    if (password.match(/\d/))
      hasNumbers = true

    if (password.match(/[^a-zA-Z\d]/))
      hasSymbols = true

    let maxWordPermitted = 6

    const passwordRegex = password.toLowerCase().match(/[a-z]+/gi)

    if (passwordRegex && passwordRegex.filter((a) => a.length > maxWordPermitted && username.toLowerCase().includes(a)).length > 0)
      hasUsername = true

    if (password == confirmPassword && password != "" && confirmPassword != "")
      isPassAndConfirmEquals = true

    Gems.ControlPassRuleStyle('has-at-least-12-chars', hasAtLeast12Chars)
    Gems.ControlPassRuleStyle('has-upper-and-lower-chars', hasUpperAndLowerChars)
    Gems.ControlPassRuleStyle('has-numbers', hasNumbers)
    Gems.ControlPassRuleStyle('has-symbols', hasSymbols)
    Gems.ControlPassRuleStyle('has-username', !hasUsername)
    Gems.ControlPassRuleStyle('is-pass-equals', isPassAndConfirmEquals)

    return { hasAtLeast12Chars, hasUpperAndLowerChars, hasNumbers, hasSymbols, hasUsername, isPassAndConfirmEquals }
  }

  static IsPasswordValid(passRules) {
    if (passRules.hasAtLeast12Chars && passRules.hasUpperAndLowerChars && passRules.hasNumbers && passRules.hasSymbols && !passRules.hasUsername && passRules.isPassAndConfirmEquals)
      return true

    return false
  }

  /*      
    static Language = (arrValues, func) => {
        return func.apply(null, arrValues);
    }
  
  */
}
export { Gems }; // Vue.js
