import Vuex from "vuex";
import authentication from "./modules/authentication";
import hierarchy from "./modules/hierarchy";

const store = new Vuex.Store({
  modules: {
    authentication,
    hierarchy
  }
});

export default store;
