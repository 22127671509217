
<script setup>
import { useStore } from "vuex";
import { Gems } from "@/gems";
import { onMounted, ref } from "vue";
import router from "@/router";
import { HUB } from "../hub";

const store = useStore();
const homeAnimationURI = require('../images/logo_hub.png')

const animationImageFileReader = new FileReader();
fetch(homeAnimationURI)
  .then(response => response.blob())
  .then(data => animationImageFileReader.readAsDataURL(data))

animationImageFileReader.onload = () => {
  localStorage.setItem('homeAnimationImageData', animationImageFileReader.result)
}

const eye = ref(true);
const txtUsername = ref(null);
const txtPassword = ref(null);
const txtMFA = ref(null);

async function Login(mfa_confirmed = false) {
  const forgotPass = document.getElementById("forgot").checked

  if (!txtUsername.value.value.includes("@") && !txtUsername.value.value.endsWith("@") === false) {
    document.getElementById("divMessage").innerText = "Introduza: [username]@[nome da empresa]";
    return;
  }

  document.getElementById("loader").innerText = "AGUARDE...";
  document.getElementById("loader").setAttribute("disabled", true);

  const credentials = {
    username: txtUsername.value.value,
    password: txtPassword.value.value,
    forgotPass,
    mfa_confirmed
  };

  const response = await store.dispatch('HasAccess', credentials);

  if (forgotPass) {
    if(response) {
      if(!response.error) {
        Gems.Telegram('Um link de recuperação de password foi enviado para o seu e-mail', 'tele-success')
        txtPassword.value.value = ''
      } else {
        Gems.Telegram(response.msg, "tele-not-allowed");
      }
    } else {
      Gems.Telegram('Ocorreu um erro', "tele-not-allowed");
    }
  } 

  document.getElementById("loader").innerText = "CONTINUAR"
  document.getElementById("loader").removeAttribute("disabled")
}

function ChangeVisibility() {
  eye.value = !eye.value;

  const password = document.getElementById("password");
  const type = password.getAttribute("type") === "password" ? "text" : "password";

  password.setAttribute("type", type);
}

function PassRecovery() {
  const chkForgot = document.getElementById("forgot")
  const divMessage = document.getElementById("divMessage")
  const chkPass = document.getElementById('password')
  
  chkPass.value = ""
  divMessage.innerText = "";
  chkPass.setAttribute("type", "password");
  chkPass.setAttribute('placeholder', "[password]");
  
  if (chkForgot.checked) {
    divMessage.innerText = 'Use o seu endereço de e-mail como Password';
    chkPass.setAttribute('placeholder', "E-mail");
    chkPass.setAttribute("type", "text");
  } 
}


function ShowRecoveryVideo() {
  const divModal = document.getElementById('div-modal');
  divModal.classList.remove('hidden');

  const button = document.getElementById('btn-more');
  button.style.border = '2px solid white';
  button.childNodes[0].style.color = 'white';

  let videoPlayer = document.getElementById("video-player");
  let divVideo = document.getElementById("div-video");

  divVideo.classList.remove('hidden');
  divVideo.style.display = "flex";

  videoPlayer.play();
}

function HideVideo() {
  const divVideo = document.getElementById("div-video")
  document.getElementById("div-modal").classList.add('hidden');

  divVideo.classList.add('hidden');
  divVideo.style.display = "";

  const button = document.getElementById('btn-more');
  button.style.border = '';
  button.childNodes[0].style.color = '';

  let video = document.getElementById("video-player");
  video.pause();
  video.currentTime = 0;
}

async function ValidateMFA () {
  const validateBtn = document.getElementById('validate-mfa')
  validateBtn.innerText = 'Aguarde...'

  const response = await HUB.CallBackEnd({
    action: HUB.Action('mfa-control'), data: {
      validate: true,
      mfa_code: txtMFA.value.value,
      user: txtUsername.value.value,
      pass: txtPassword.value.value,
      trust_device: document.getElementById('trust-device').checked
    }
  })

  validateBtn.innerText = 'Validar código'

  if (response.data.mfa_expired) {
    Gems.Telegram(response.msg, "tele-not-allowed")
    return
  }

  if (!response.data.mfa_correct) {
    Gems.Telegram(response.msg, "tele-info", "")
    return
  }

  Gems.Telegram('Autenticado com sucesso', "tele-success")

  Login(true)
}

async function RequestNewMFA() {
  const requestBtn = document.getElementById('request-mfa')
  requestBtn.innerText = 'Aguarde...'
  requestBtn.style.pointerEvents = 'none'

  await HUB.CallBackEnd({
    action: HUB.Action('mfa-control'), data: {
      new: true,
      user: txtUsername.value.value,
      pass: txtPassword.value.value,
    }
  })

  requestBtn.style.pointerEvents = 'all'
  requestBtn.innerText = 'Solicitar novo código'
  Gems.Telegram("<strong>Verificação de segurança</strong> <br/> <br/> Um código de verificação foi enviado para o seu e-mail", "tele-info", "")
}

onMounted(() => {
  if (router.currentRoute.value.query.message)
    document.getElementById("divMessage").innerText = router.currentRoute.value.query.message;

  if (history.state?.change)
    document.getElementById("divMessage").innerText = history.state.message
})

</script>

<template>
  <div id="div-main-background">

    <div class="div-left-side">
      <div id="div-inpeople-logo"></div>

      <div class="div-new-login-txt" v-show="!store.getters.ShowMFA">O seu novo acesso <span>Cirvalue</span></div>
    </div>


    <form v-show="!store.getters.ShowMFA" class="form" @submit.prevent="Login()">
      <div class="div-welcome">
        <div class="lbl-app">Bem-vindo</div>
        <div class="div-login">Login</div>
      </div>

      <input type="text" ref="txtUsername" class="user-input" placeholder="[username]@[empresa]" required autocomplete="off">

      <div id="div-password">
        <input type="password" id="password" ref="txtPassword" class="user-input" placeholder="[password]" required
          autocomplete="off">

        <div id="eye-password" class="eye-password">
          <i @click="ChangeVisibility()" class="fas" :class="{ 'fa-eye': eye, 'fa-eye-slash': !eye }"></i>
        </div>
      </div>

      <div class="div-recovery">
        <input type="checkbox" class="chk clickable" id="forgot" @click="PassRecovery()" />
        <label class="lbl-text">Esqueci a password</label>
      </div>
      <div id="divMessage" class="div-message-alert"></div>

      <button id="loader" type="submit" class="btn-main">
        Continuar
      </button>

      <button id="btn-more" type="button" class="btn-more">
        <a @click="ShowRecoveryVideo()">Obter/Recuperar password</a>
      </button>

      <div class="div-other">

        <div class="more">
          <p class="p-recomendation"> Browsers Chrome ou Edge recomendados </p>
        </div>
      </div>
    </form>
    
    <div v-show="store.getters.ShowMFA" class="form">
      <div class="div-lbl-mfa">Código de verificação</div>

      <input type="text" ref="txtMFA" class="user-input mfa-input" placeholder="______" autocomplete="off" maxlength="6">

      <div class="div-trust-device">
        <input type="checkbox" class="chk clickable" id="trust-device" />
        <label class="lbl-text">Confiar neste dispositivo</label>
      </div>

      <button id="validate-mfa" class="btn-main" @click="ValidateMFA()"> Validar código </button>

      <div class="div-back-to-login">
        <div @click="store.commit('showMFA', false)">
          <span>Voltar ao login</span>
        </div>
        <div>
          <span id="request-mfa" @click="RequestNewMFA()">Solicitar novo código</span>
        </div>
      </div>
    </div>

    <div id="div-modal" class="hidden"></div>
    <div id="div-video" class="hidden">
      <video id="video-player" controls>
        <source src="../videos/pass-recovery.mp4" type="video/mp4" />
        Your browser does not support HTML video.
      </video>
      <div>
        <i id="ico-times" class="far fa-times-circle fa-2x" @click="HideVideo()"></i>
      </div>
    </div>

  </div>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: black !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

body {
  background-color: #080710;
}

.form {
  width: 30%;
  align-self: center;
}

.form * {
  border: none;
  outline: none;
}

#div-main-background {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  height: 100vh;
  margin: 0 auto;
  background-size: cover;
  backdrop-filter: saturate(1.5) !important;
  background-image: linear-gradient(211deg, rgb(0 255 228) 0%, rgb(0 255 221) 0%, rgb(182 124 255) 80%, rgb(153 94 255) 160%);
}

#div-modal {
  background-color: black;
  position: fixed;
  width: 100%;
  z-index: 90;
  left: 0;
  top: 0;
  opacity: 0.5;
  height: 100vh;
}

#div-video {
  position: absolute;
  z-index: 91;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  gap: 15px;
}

#div-video :last-child {
  float: right;
  margin-top: -18px;
}

#video-player {
  width: 70%;
  height: 60%;
}

#ico-times {
  color: grey;
  cursor: pointer;
}

.div-login {
  font-size: 1.7em;
}

.div-new-login-txt {
  font-size: 2em;
  color: white;
  font-weight: 300;
}

.div-new-login-txt span {
  font-weight: 500;
}

.div-left-side {
  display: flex;
  justify-content: center;
  flex-direction: column;
  vertical-align: middle;
  margin: auto 0;
  gap: 103px;
  width: 450px;
}

#div-inpeople-logo {
  align-self: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 400px;
  height: 100px;
  background-image: url('../images/logo_inpeople_hub_login.png');
  margin-top: 90px;
  margin-right: -65px;
}

.div-welcome,
.div-lbl-mfa {
  color: white;
  font-size: 1.2em;
  margin-bottom: 40px;
  text-align: left;
  font-family: "Space Grotesk";
}

.div-lbl-mfa {
  font-family: "Poppins";
  font-size: 2.5em;
  color: black;
  font-weight: 600;
  text-align: center;
}

.lbl-app {
  font-family: "Poppins";
  font-size: 4em;
  font-weight: 300;
  color: black;
  margin-left: -5px;
}

label {
  display: block;
  margin-top: 0px;
  font-size: 14px;
}

label:first-child {
  margin-top: 10px;
}

input {
  width: 100%;
  margin: 8px 0;
  display: block;
  padding: 0 10px;
  background-color: #ffffff00;
  font-weight: 600;
  border-bottom: 2px solid black !important;
  font-size: 1em;
}

::placeholder {
  color: black;
}

.btn-main {
  width: 100%;
  padding: 25px;
  border-radius: 10px;
  color: white;
  background-color: black;
  font-size: 1.1em;
  text-transform: uppercase;
  cursor: pointer;
}


.btn-more {
  width: 100%;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid black;
  margin-top: 10px;
  height: 70px;
  cursor: pointer;
}

.btn-more a {
  text-decoration: none;
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
}

.btn-more strong {
  margin-left: 5px;
}


.btn-more:hover {
  border: 2px solid white;
}

.btn-more:hover a {
  color: white;
}

.btn-main:hover {
  background-color: rgb(205 176 255);
}

#div-password {
  margin-bottom: 20px;
}

.recovery {
  margin-top: 19px;
}

.div-other label {
  color: #eaf0fb;
}

.div-recovery,
.div-trust-device {
  display: flex;
  padding: 10px 0 0px;
  align-items: center;
  font-weight: 600;
}

.div-trust-device {
  padding: 10px 0px 60px;
}

.div-other div:last-child {
  color: #eaf0fb;
}

.div-message-alert {
  font-size: .9em;
  text-align: center;
  color: #fff;
  padding: 1%;
  min-height: 6vh;
}


.chk {
  width: 20px;
  margin-right: 5px;
  transform: scale(1.5);
}

.lbl-text {
  color: black;
}

.user-input {
  padding: 25px 15px !important;
  color: black !important;
  margin-top: 30px;
}

.mfa-input::placeholder {
  font-weight: lighter;
  text-align: center;
}

.mfa-input::-ms-input-placeholder {
  font-weight: lighter;
  text-align: center;
}

.mfa-input {
  letter-spacing: .3em;
  text-align: center;
  font-size: 1.7em;
}

.more {
  display: flex;
  justify-content: center;
}

.more a {
  color: #eaf0fb;
  text-decoration: none;
  font-size: initial;
}

.btn-loader {
  background-color: #b0c3c0 !important;
}

.eye-password {
  position: relative;
  width: 20px;
  top: -50px;
  left: 95%;
}

.eye-password i {
  font-size: .8em;
  cursor: pointer;
  color: black;
  top: 0;
  position: absolute;
}

/* TELEGRAM CSS */
.tele-info {
  text-align: left;
  background-color: #ffffff;
  border: solid 1px #aaaaaa;
}

.tele-success {
  text-align: center;
  color: var(--light-gray-color);
  background-color: rgb(35, 160, 50);
  border: solid 1px rgb(0, 140, 20);
  font-size: 10pt;
}

.tele-not-allowed {
  text-align: center;
  color: rgb(50, 0, 0);
  background-color: rgb(255, 85, 70);
  border: solid 1px orangered;
  font-size: 10pt;
}

.p-recomendation {
  font-size: 0.8em !important;
  opacity: 0.5;
  margin-top: 15px;
}

#password::-ms-clear,
#password::-ms-reveal {
  display: none;
}

#validate-mfa {
  margin-top: 2px;
}

#request-mfa {
  margin-top: 10px;
}

.div-back-to-login {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.div-back-to-login div:hover {
  color: black;
  cursor: pointer;
}

.div-back-to-login i {
  margin-right: 10px;
}


@media only screen and (max-width: 800px) {

  #div-main-background {
    justify-content: space-evenly;
    flex-direction: column;
    padding: 0% 5%;
  }

  .div-left-side {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
  }

  #div-inpeople-logo {
    width: 250px;
    height: 80px;
    align-self: flex-start;
    margin: 0;
  }

  .form {
    width: 99%;
  }

  .div-welcome {
    color: black;
    font-weight: bold;
  }

  .lbl-app {
    margin-bottom: 5px;
  }
}
</style>
